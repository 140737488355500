import { React } from 'react';
import {
  Box,
  Flex,
  Text,
  Button,
  Icon,
  chakra,
  useDisclosure
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useAnimation } from 'framer-motion'
import { Link } from "react-router-dom"
import ReactGA from "react-ga4"

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID)

const handleButtonClick = () => {
  const event = ReactGA.event('get_a_quote', {
    category: 'Clicks',
    action: 'Get A Quote',
    label: '/quote',
  });
  console.log(event)

};

const AnimatedIcon = chakra(Icon, {
  baseStyle: {
    transition: 'all 0.3s ease',
  },
});

function GetAQuote() {
  const { isOpen, onToggle } = useDisclosure();
  const animationRef = useAnimation();

  const handleMouseEnter = () => {
    onToggle();
    animationRef.start({ opacity: 1, x: '5px' });
  };

  const handleMouseLeave = () => {
    onToggle();
    animationRef.start({ opacity: 0, x: '0px' });
  };

  return (
    <Box
      as="footer"
      bg="gray.800"
      w="100%"
      py="4"
      mt={"auto"}
      position="fixed"
      bottom={"0"}
    >
      <Flex
        justifyContent={"center"}
        alignItems="center"
        maxW="7xl"
        mx={"auto"}
        flexDirection={["column", "row"]}
      >
        <Text color="white" fontSize={["lg", "xl"]} pr={["0", "24px"]}>
          Discuss your insurance today.
        </Text>
        <Link to={"/quote"}>
        <Button
        onClick={handleButtonClick}
          rightIcon={
            <AnimatedIcon
              as={ChevronRightIcon}
              w="5"
              h="5"
              ref={animationRef}
              opacity={isOpen ? 1 : 0}
              transform={isOpen ? 'translateX(5px)' : 'translateX(0px)'}
            />
          }
          borderRadius={"20"}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          bg={"#5B5F97"}
          color={"white"}
          _hover={{bg: "#5F5FF7"}}
        >
          <Text ml={"4"}>GET A QUOTE</Text>
        </Button>
        </Link>
      </Flex>
    </Box>
  );
}

export default GetAQuote;


